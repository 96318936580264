export enum ApiComponentType {
    FIT_IF_LIST = 'cource-lists.fit-if-list',
    THEMES_LIST = 'cource-lists.themes-list',
    FAQ_LIST = 'cource-lists.faq-list',
    AUTHORS_LIST = 'cource-lists.authors',
    HOME_OVERVIEW = 'home-page.home-overview',
    HOME_INFO_BLOCK = 'home-page.info-block',
    LEARN_BLOCK_LIST = 'home-page.learn-block-list',
    STATISTICS_GRID = 'home-page.statistics-grid',
    APP_SCREENSHOTS = 'home-page.home-app-overview'
}

enum ComponentKey {
    faqList = 'faqList',
    themesList = 'themesList',
    fitIfBlocks = 'fitIfBlocks',
    authors = 'authors',
    blocks = 'Blocks'
}

export type ApiComponent = {
    [key in ComponentKey]: any;
} & {
    id: number;
    __component: ApiComponentType;
};


export interface FitIfComponent {
    id: number;
    Image: {
        data: ImageComponent
    },
    Description: string;
}

export interface FitIfListComponent extends ApiComponent {
    fitIfBlocks: Array<FitIfComponent>;
}

export interface ImageComponent {
    id: number;
    attributes: {
        alternativeText: string;
        caption: string;
        createdAt: string;
        ext: string;
        hash: string;
        height: number;
        mime: string;
        name: string;
        previewUrl: string | null;
        provider: string;
        provider_metadata: string;
        size: number;
        updatedAt: string;
        url: string;
        width: number;
    }
}

export interface ThemeComponent {
    id: number;
    Title: string;
    Image?: {
        data: ImageComponent;
    }
    Description: string;
}

export interface ThemesListComponent extends ApiComponent {
    themesList: Array<ThemeComponent>
}

export interface FaqComponent {
    id: number;
    Question?: string;
    Answer?: string;
}

export interface FaqListComponent extends ApiComponent {
    faqList: Array<FaqComponent>;
}

export interface AuthorComponent {
    id: number;
    Description?: string;
    Title?: string;
    Image?: {
        data: ImageComponent
    }
}

export interface AuthorListComponent extends ApiComponent {
    authors: Array<AuthorComponent>;
}

export interface HomeOverviewComponent extends ApiComponent {
    AppStore?: string;
    Title?: string;
    BgImage?: {
        data: ImageComponent
    },
    Description?: string;
    PlayMarket?: string;
}

export interface InfoBlockComponent extends ApiComponent {
    Title?: string;
    Description?: string;
}

export interface StatisticBlockComponent {
    id: number;
    Caption?: string;
    Value?: string;
}

export interface StatisticGridComponent extends ApiComponent {
    Blocks: StatisticBlockComponent[];
}

export interface LearnBlockComponent {
    id: number;
    Title?: string;
    ImageLink?: string;
    Description?: string;
    Image?: {
        data: ImageComponent
    }
}

export interface LearnGridComponent extends ApiComponent {
    Blocks: LearnBlockComponent[];
}

export interface AppScreenShotComponent {
    Caption: string;
    Title: string;
    Image: {
        data: ImageComponent;
    },
    MobImage: {
        data: ImageComponent;
    }
}

export interface AppScreenShotsComponent extends ApiComponent {
    Blocks: AppScreenShotComponent[];
}
