import './styles.scss';
import React, {memo, useEffect, useMemo} from 'react';
import {WITH_API_BASE} from "../../configs/appConfigs";
import {LearnGridComponent} from "../../types/ApiComponent";

type Props = {
    data: LearnGridComponent
}

const LearnBlockList = ({data}: Props) => {
    const renderBlocks = useMemo(() => {
        return data.Blocks.map((block, index) => (
            <div key={`item-${index}`} className="learnBlock">
                <div className="learnBlockImage">
                    <img src={WITH_API_BASE(block?.ImageLink || '')} width={204} alt=""/>
                </div>
                <div className="learnBlockTitle">
                    {block.Title}
                </div>
                <div className="learnBlockDesc">
                    {block.Description}
                </div>
            </div>
        ))
    }, [data])

    return (
        <div id={'skills'} className="container">
            <div className="learnBlockGrid">
                {renderBlocks}
            </div>
        </div>
    )
}

export default memo(LearnBlockList);
