import './styles.scss';
import React, {memo} from 'react';
import {InfoBlockComponent} from "../../types/ApiComponent";

type Props = {
    data: InfoBlockComponent
}

const HomeInfoBlock = ({data}: Props) => {
    return (
        <div className="container infoBlock">
            <div className="infoBlockTitle">
                {data?.Title || ''}
            </div>
            <div className="infoBlockDesc">
                {data?.Description || ''}
            </div>
        </div>
    )
}

export default memo(HomeInfoBlock);
