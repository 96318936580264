import './styles.scss';

import React, {memo, useMemo} from 'react';
import {AppScreenShotsComponent} from "../../types/ApiComponent";
import {WITH_API_BASE} from "../../configs/appConfigs";
import {CrownImage, apple, arrRight2} from "../../images";
import Slider from "react-slick";
import {Link} from "gatsby";

type Props = {
    data: AppScreenShotsComponent
}

const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 1
};
const settingsMob = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1
};

const HomeScreenshots = ({data}: Props) => {
    const renderList = useMemo(() => {
        return data.Blocks.map((screenshot, index) => {
            return (
                <div className="appScreenshotsScreen" key={index}>
                    <div className="appScreenshotsTitle">{screenshot?.Title || ''}</div>
                    <div className="appScreenshotsImage">
                        <img src={WITH_API_BASE(screenshot.Image.data.attributes.url)} alt=""/>
                    </div>
                </div>
            )
        })
    }, [data]);

    const renderMobileList = useMemo(() => {
        return data.Blocks.map((screenshot, index) => {
            return (
                <div className="appScreenshotsScreenMob" key={index}>
                    <div className="appScreenshotsScreenMobInner">
                        <div className="appScreenshotsTitle">{screenshot?.Title || ''}</div>
                        <div className="appScreenshotsImage">
                            {
                                screenshot?.MobImage?.data ? (
                                    <img src={WITH_API_BASE(screenshot.MobImage.data.attributes.url)} height={336} alt=""/>
                                ) : (
                                    <img src={WITH_API_BASE(screenshot.Image.data.attributes.url)} height={336} alt=""/>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        })
    }, [data])

    return (
        <>
            <div className="container appScreenshots">
                <Slider initialSlide={0} {...settings} className="appScreenshotsScreenPc">
                    {renderList}
                </Slider>
            </div>

            <div className="container mobAppScreenshots">
                <Slider initialSlide={0} centerMode variableWidth adaptiveHeight {...settingsMob} className="appScreenshotsScreenMobile">
                    {renderMobileList}
                </Slider>
            </div>

            <div className="buttonBlock2">
                <Link to="https://apps.apple.com/us/app/aeer-education-productivity/id1630377140?l=ru" className="btnv2 btn-primary">
                    <img src={apple} className="btn-img apple" width={24} alt=""/>
                    <span>Download on the App Store</span>
                    <img src={arrRight2} className="btn-img" width={30} alt=""/>
                </Link>
            </div>
        </>
    )
}

export default memo(HomeScreenshots)
