import {AnchorLink} from "gatsby-plugin-anchor-links";
import React, {memo} from 'react';

import {
    CrossIcon
} from "../../images";
import {Link} from "gatsby";


type Props = {
    onClick: () => any,
    relation: string
}

const QuizFooter = ({onClick, relation}: Props) => {

    const handleClick = () => {
        onClick();
    }

    return (
        <>
        {
            (relation === 'Home') && (
                <div className="mobileNavigation">
                    <div className="mobileNavigationHead">
                        <button  onClick={() => handleClick()}><img src={CrossIcon} width={24} height={24} alt=""/></button>
                    </div>
                    <ul className="mobileNavigationNav">
                        
                        <li><AnchorLink stripHash to="/#tools" onAnchorLinkClick={() => handleClick()}>Tools</AnchorLink></li>
                        <li><Link to="/list">Our Courses</Link></li>
                        <li><AnchorLink stripHash to="/#preorder" onAnchorLinkClick={() => handleClick()}>Teach on AEER</AnchorLink></li>
                        <li><AnchorLink stripHash to="/#preorder" onAnchorLinkClick={() => handleClick()}>AEER for Business</AnchorLink></li>
                        <li><a href="https://aeer-partners.com/">Affiliate</a></li>
                        <li><AnchorLink stripHash to="/#contacts" onAnchorLinkClick={() => handleClick()}>Contacts</AnchorLink></li>
                    </ul>
                </div>
            )
        }
        {
            (relation === 'Corp') && (
                <div className="mobileNavigation">
                    <div className="mobileNavigationHead">
                        <button  onClick={() => handleClick()}><img src={CrossIcon} width={24} height={24} alt=""/></button>
                    </div>
                    <ul className="mobileNavigationNav">
                        <li><AnchorLink stripHash to="#tools" onAnchorLinkClick={() => handleClick()}>Tools</AnchorLink></li>
                        <li><AnchorLink stripHash to="#skills" onAnchorLinkClick={() => handleClick()}>Courses</AnchorLink></li>
                        <li><AnchorLink stripHash to="#aiAeer" onAnchorLinkClick={() => handleClick()}>Our AI Assistant</AnchorLink></li>
                        <li><AnchorLink stripHash to="#contacts" onAnchorLinkClick={() => handleClick()}>Contacts</AnchorLink></li>
                    </ul>
                </div>
            )
        }
        {
            (relation !== 'Home' && relation !== 'Corp') && (
                <div className="mobileNavigation">
                    <div className="mobileNavigationHead">
                        <button  onClick={() => handleClick()}><img src={CrossIcon} width={24} height={24} alt=""/></button>
                    </div>
                    <ul className="mobileNavigationNav">
                        
                        <li><Link to="/#tools">Tools</Link></li>
                        <li><Link to="/list">Our Courses</Link></li>
                        <li><Link to="/#preorder">Teach on AEER</Link></li>
                        <li><Link to="/#preorder">AEER for Business</Link></li>
                        <li><a href="https://aeer-partners.com/">Affiliate</a></li>
                        <li><Link to="/#contacts">Contacts</Link></li>
                    </ul>
                </div>
            )
        }
        </>
    )
}

export default memo(QuizFooter);
