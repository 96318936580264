import './styles.scss';
import React, {memo, useMemo} from 'react';
import {StatisticGridComponent} from "../../types/ApiComponent";


type Props = {
    data: StatisticGridComponent
}

const HomeOverview = ({ data }: Props) => {

    const renderGrid = useMemo(() => {
        return data.Blocks.map((block, index) => (
            <div key={`item-${index}`} className="statisticBlockItem">
                <div className="statisticBlockVal">{block.Value}</div>
                <div className="statisticBlockCap">{block.Caption}</div>
            </div>
        ))
    },[data])

    return (
        <div className="container statisticBlock">
            <div className="statisticBlockTitle">Millions of users will be training with us</div>
            <div className="statisticBlockGrid">
                {renderGrid}
            </div>
        </div>
    )
}

export default memo(HomeOverview);
