import './styles.scss';
import React, {memo} from 'react';

import {
    AvatarImage,
    BigLogoRight,
    GoldAppStoreImage,
    GoldPlayMarketImage,
    MasterCardImage,
    VisaImage
} from "../../images";
import { Link } from 'gatsby';

const MainFooter = () => {

    return (
        <footer className="container footer" id={'contacts'}>
            <div className="footerLeft">
                <div className="footerLeftAvatar">
                    <img src={AvatarImage} width={96} height={96} alt=""/>
                </div>
                <div className="footerLeftData">
                    <div className="footerLeftPosition">СЕО</div>
                    <div className="footerLeftName">Andrew Filatov</div>
                    <div className="footerLeftDesc">
                        My goal is to create a product that helps people improve themselves
                    </div>
                </div>
            </div>

            <div className="footerCenter">
                <div className="footerPayments">
                    <div>
                        <img src={MasterCardImage} width={28} alt=""/>
                    </div>
                    <div>
                        <img src={VisaImage} width={30} alt=""/>
                    </div>
                </div>
                <div className="footerStores">
                    <div>
                        <a href="https://apps.apple.com/us/app/aeer-education-productivity/id1630377140?l=ru">
                            <img width={110} src={GoldAppStoreImage} alt=""/>
                        </a>
                    </div>
                    <div>
                        <a href="#">
                            <img width={110} src={GoldPlayMarketImage} alt=""/>
                        </a>
                    </div>
                </div>
                <div className="footerLinks">
                    <Link to="/terms">Terms of User</Link>
                    <Link to="/privacy">Privacy policy</Link>
                    <Link to="/cookie">Cookie policy</Link>
                    <Link to="/cancel">Сancel subscription</Link>
                </div>
            </div>

            <div className="footerRight">
                <img width={180} className="footerLogo" src={BigLogoRight} alt=""/>
                <div className="footerCopy">
                    &copy; AEER PLATFORM INC
                </div>
                <address className="footerAddress">
                    8 THE GREEN STE A DOVER, DE 19901
                </address>
            </div>
        </footer>
    )
}

export default memo(MainFooter);
