import {BaseApiService} from "./baseApiService";

import {WITH_API_BASE} from "../configs/appConfigs";
import {BasePageResponse} from "../types/ApiModels"
import {
    ApiComponent
} from "../types/ApiComponent";

export type HomePageComponents = Array<ApiComponent>;

class PageService extends BaseApiService {
    public static async getCourseById(id: number): Promise<BasePageResponse<HomePageComponents>> {
        return this.client().get(WITH_API_BASE(`/api/pages/${id}?populate=deep,5`));
    }
}

export default PageService;
