import axios, {AxiosInstance} from "axios";

const token = 'e314ddf4e7821c509b65baa92393da10b622fc12f0165c35cafe030f037fa8dcd6856bf4ca55739007833d1f6e9304430ffd38cc424fab6482246209566e033000d548d5bbcf15602a334a732b0883f68fb03d1e7c4aa26bb7a3d86aeb77c9b77fac3a6dfc14eb104fc3ded4b8d433c097652fb2b9cd0bd03587f270f3e2bc19';

export interface BaseApiResponse<T> {
    data: T,
    meta: unknown;
}

export class BaseApiService {
    protected static client(): AxiosInstance {
        return axios.create({
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
}
